import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";

import AWS_AppSync from "../AppSync.json";
import { getIdentityId } from "./cognito";
import { AsYouType } from "libphonenumber-js";
import mime from "mime-types";
import { parsePhoneNumberFromString } from "libphonenumber-js";
export { AWS_AppSync };
import {signOut} from '../lib/cognito';
import * as moment from 'moment';
import {SDOH_RISK_CLASSIFICATION} from '@/constants';

/**
 * Returns a hash code for a string.
 * (Compatible to Java's String.hashCode())
 *
 * The hash code for a string object is computed as
 *     s[0]*31^(n-1) + s[1]*31^(n-2) + ... + s[n-1]
 * using number arithmetic, where s[i] is the i th character
 * of the given string, n is the length of the string,
 * and ^ indicates exponentiation.
 * (The hash value of the empty string is zero.)
 *
 * @param {string} s a string
 * @return {number} a hash code value for the given string.
 */
export function hashCode(s) {
  var h = 0,
    l = s.length,
    i = 0;
  if (l > 0) while (i < l) h = ((h << 5) - h + s.charCodeAt(i++)) | 0;
  return h;
}

const MATERIAL_COLORS = [
  "#F56A00",
  "rgb(249, 166, 109)",
  "rgb(87, 190, 140)",
  "rgb(132, 122, 140)",
  "rgb(236, 94, 68)",
  "rgb(33, 33, 33)",
];

export function stringToColor(s, colors = null) {
  const code = hashCode(s);
  const _colorsArray = colors ? colors : MATERIAL_COLORS;
  const idx = code % _colorsArray.length;
  return _colorsArray[idx];
}

export function generateTeamLinks(canViewPolicy) {
  const headerLinks = [];
  if (canViewPolicy("USER")) {
    headerLinks.push({ title: "Members", path: "/members" });
  }
  if (canViewPolicy("USER_GROUP")) {
    headerLinks.push({ title: "User Groups", path: "/usergroups" });
  }
  if (canViewPolicy("ROLES")) {
    headerLinks.push({ title: "Roles", path: "/roles" });
  }
  return headerLinks;
}

export function firstValidTeamLink(canViewPolicy) {

  if (canViewPolicy("USER")) {
    return "/members";
  }
  if (canViewPolicy("USER_GROUP")) {
    return "/usergroups";
  }
  if (canViewPolicy("ROLES")) {
    return "/roles";
  }
  return null;
}

export async function createPrivateAWSFile(file) {
  const cognitoIdentityId = getIdentityId();
  const { name: fileName } = file;
  const [, , , extension] = /([^.]+)(\.(\w+))?$/.exec(fileName);
  // if no mimetype detected (windows chrome) try to set it based on extension
  const mimeType = !file.type ? mime.lookup(extension) : file.type;
  const key = [uuidv4(), extension].filter((x) => !!x).join(".");
  const keyWithContext = `private/${cognitoIdentityId}/${key}`;
  const awsFile = {
    key: keyWithContext,
    bucket: AWS_AppSync.bucket,
    region: AWS_AppSync.region,
    mimeType,
    localUri: file,
    visibility: "private",
  };
  return awsFile;
}

export function createPrivateAWSPatientDocument(file, props) {
  const today = moment.utc();
  const year = today.year();
  const month = today.month();
  const day = today.date();
  const randomInteger = Math.floor(Math.random()*100);
  const patientId = props.patientData.id;
  const { name: fileName } = file;
  const fileNameOnly = fileName.split('.').slice(0, -1).join('.')
  const extension = fileName.substr(fileName.lastIndexOf('.') + 1)
  // if no mimetype detected (windows chrome) try to set it based on extension
  const mimeType = !file.type ? mime.lookup(extension) : file.type;
  const key = [uuidv4(), extension].filter((x) => !!x).join(".");
  const keyWithContext = `s3://${AWS_AppSync.bucket}/private/patients/${patientId}/${year}/${month}/${day}/${randomInteger}/${key}`;
  const awsFile = {
    key: keyWithContext,
    mimeType,
    name: fileNameOnly
  };
  return awsFile;
}

export async function logout(apolloClient) {
  const result = await signOut();
  if (apolloClient) {
    apolloClient.cache.reset();
  }
  window.location = "/?sessionTimedOut=true";
}

export function formatPhone(phone) {
  if (!phone) {
    return "";
  }
  return phone.replace(/(\d{3})(\d{3})(\d{4})(x\d{5})?/, "($1) $2-$3 $4");
}

export function formatPhoneForDB(phone) {
  const digitsOnly = phone.replace(/\D/g, "");
  if (digitsOnly.length > 10) {
    return digitsOnly.slice(0, 10) + "x" + digitsOnly.slice(10, 16);
  }
  return digitsOnly;
}

export function removeEmpty(obj) {
  Object.keys(obj).forEach(
    (k) => !obj[k] && obj[k] !== undefined && delete obj[k]
  );
  return obj;
}

export function safeParsePhoneNumberFromString(num, countryCode = "US") {
  try {
    return parsePhoneNumberFromString(num, countryCode);
  } catch (e) {
    // log error somewhere? nah.
    // return null for invalid phone numbers
    return null;
  }
}

export function safeParseFormatPhoneFromString(num, countryCode = "US") {
  const parsedNumber = parsePhoneNumberFromString(String(num), countryCode);
  if (!parsedNumber) return num && String(num);
  try {
    return parsedNumber.formatNational();
  } catch (e) {
    // additional check just in case
    return num && String(num);
  }
}

export function getFieldFormattedPhoneValue(
  newPhoneValue,
  oldPhoneValue,
  extensionMax = 5
) {
  const MAX_DIGITS = 10;
  const oldDigitsOnly = formatPhoneForDB(oldPhoneValue);
  const digitsOnly = formatPhoneForDB(newPhoneValue);
  if (newPhoneValue === "1") {
    return "";
  }
  if (digitsOnly.length > MAX_DIGITS + 1 + extensionMax) {
    // Arbitrarily limiting to 5 digit extension
    return safeParseFormatPhoneFromString(oldDigitsOnly, "US");
  }
  if (digitsOnly.length > MAX_DIGITS) {
    return safeParseFormatPhoneFromString(digitsOnly, "US");
  }
  if (newPhoneValue.length === 4) {
    // Fix for AsYouType bug that prevents backspacing through area code
    newPhoneValue.slice(0, -1);
    return newPhoneValue.replace(/\D/g, "");
  }
  return new AsYouType("US").input(digitsOnly);
}

export const fromEntries = (iterable) => {
  return [...iterable].reduce((obj, [key, val]) => {
    obj[key] = val;
    return obj;
  }, {});
};

export function clamp(num, min, max) {
  return num <= min ? min : num >= max ? max : num;
}

export const compareScalarArrays = (arr1, arr2) => {
  if (!arr1 || !arr2) {
    return false;
  }
  return (
    arr1.length === arr2.length &&
    arr1.sort().every(function (value, index) {
      return value === arr2.sort()[index];
    })
  );
};

export const normalize = (val, min, max) => {
  return (val - min) / (max - min);
};

export const getNextIntervalDateString = (interval, datetime) => {
  const dtObj = datetime || dayjs();
  const nextInterval =
    (Math.ceil(dtObj.minute() / interval) * interval) % 60 || 60;
  const minuteDiff = nextInterval - dtObj.minute();
  dtObj.add(minuteDiff, "minute");
  return dtObj.format();
};

export const isFlagEnabled = (viewer, flag) => {
  const flags =
    viewer?.currentOrganization?.featureFlags.map((f) => f.key) || [];
  return flags.includes(flag);
};

export const range = (start, end, step = 1) => {
  const values = [];
  for (let value = start; value < end; value += step) {
    values.push(value);
  }
  return values;
};

export const strPad = (n) => {
  return String("00" + n).slice(-2);
};

export const slugify = (txt) => txt.toLowerCase().replace(/ /g,'_').replace(/[^\w-]+/g,'');

export const getIdentifierValues = (identifiers, type, system, use='USUAL') =>
  getIdentifierObjects(identifiers, type, system, use).map(x => x.value);

export const getIdentifierValue = (identifiers, type, system, use='USUAL') =>
  getIdentifierValues(identifiers, type, system, use)[0];

export const getIdentifierObjects = (identifiers, type, system, use='USUAL') =>
  identifiers && identifiers.filter(x => (!use || x.use === use) && (!type || (x.type && x.type.code === type)) && (!system || x.system === system));

export const getIdentifierObject = (identifiers, type, system, use='USUAL') =>
getIdentifierObjects(identifiers, type, system, use)[0];


export const getRiskClassificationTitle = (value) => {
  if (value) {
    const riskScore = parseInt(value);
    const riskClassificationItem = SDOH_RISK_CLASSIFICATION
      .find(({min, max}) => riskScore >= min && riskScore <= max);

    return riskClassificationItem?.title || undefined;
  }

  return undefined;
}

export const getWidthOfTheTextInPx = (text) => {
  let textWidth = 0

  if (text) {
    const spanEl = document.createElement('span');
    spanEl.textContent = text;
    spanEl.style.opacity = '0';
    spanEl.style.position = 'absolute';
    spanEl.style.left = '-5000px';

    document.body.appendChild(spanEl);

    textWidth = spanEl.offsetWidth;
    spanEl.remove();
  }

  return textWidth;
}

let uuidForFormUrl = ''

export const getCustomFormsWidgetUrl = (currentOrganization, useNgVersion = true) => {

  var shouldUseGCP = false;
  if (currentOrganization) {
    shouldUseGCP = currentOrganization.featureFlags?.find(ff => ff.key === 'USE_GCP_FOR_CUSTOM_FORMS') || false;
  }

  console.log("Custom forms, shouldUseGCP ?", shouldUseGCP);

  if (shouldUseGCP) {
    console.log("Using GCP");
    window.cf_config = AWS_AppSync.customFormsConfig
  }
  //  else if (useNgVersion) {
  //   window.cf_config = AWS_AppSync.customFormsAmplifyConfig;
  // }

  const host = shouldUseGCP ? AWS_AppSync.customFormsWidgetsHostGCP : AWS_AppSync.customFormsWidgetsHost;
  const ext = shouldUseGCP || useNgVersion ? 'NG' : '';

  if (!uuidForFormUrl) {
    uuidForFormUrl = uuidv4()
  }

  return `${host}/widgets/formsWidgets${ext}.js?uuid=${uuidForFormUrl}`;
}

export const getProviderCondition = (condition) => {
  return condition === undefined ? true : condition
}

export const validatePropertyInObject = (keyObject, value, setValue) => {
  if(keyObject?.hasOwnProperty(value)){
    setValue(keyObject[value])
  }
}

export const durationAsString = (start, end) => {
  const duration = moment.duration(moment(end).diff(moment(start)));

  //Get Days
  const days = Math.floor(duration.asDays()); // .asDays returns float but we are interested in full days only
  const daysFormatted = days ? `${days} days ` : ''; // if no full days then do not display it at all

  //Get Hours
  const hours = duration.hours();
  const hoursFormatted = `${hours} hours `;

  return [daysFormatted, hoursFormatted].join('');
}

export const getFullAddress = (address) => {
  if (!address) {
    return '';
  }
  return [
    address[0].line?.[0],
    [address[0].city, address[0].state, address[0].postalCode].filter(i => i).join(' '),
  ].join('\n');
}

export const deleteAllWiardSignatures = (localStorageId) => {
  const signatureIds = JSON.parse(localStorage.getItem(localStorageId)) || []
  signatureIds?.map(obj => {
    localStorage.removeItem(obj)
    localStorage.removeItem(`${obj}_flat`)
  })
  localStorage.removeItem(localStorageId)
}

const mappedRoleData = (viewerData) => {
  const roleMap =
    (viewerData &&
      viewerData.currentPolicyMap &&
      JSON.parse(viewerData.currentPolicyMap)) ||
    {};
  const extendedPolicies =
    (viewerData &&
      viewerData.currentPolicyMap &&
      JSON.parse(viewerData.currentPolicyMap)?._EXTENDED_POLICIES_) ||
    [];
  return { roleMap, extendedPolicies };
};

export const canUsePolicy = (viewerData, policy, actions) => {
  const { roleMap, extendedPolicies } = mappedRoleData(viewerData);
  const isStaff = viewerData && viewerData.isStaff;

  let canView = false;

  // Check if the policy exists in the main roleMap
  if (roleMap) {
    const roleAction = roleMap[policy]?.["action"];
    const roleEffect = roleMap[policy]?.["effect"];
    canView = actions.includes(roleAction) && roleEffect === "ALLOW";
  }

  // Check if the policy exists in the extended policies
  if (!canView) {
    for (const extendedPolicy of extendedPolicies) {
      if (extendedPolicy["resource"] === policy) {
        const extendedAction = extendedPolicy["action"];
        const extendedEffect = extendedPolicy["effect"];
        canView = actions.includes(extendedAction) && extendedEffect === "ALLOW";
        if (canView) break; // Break the loop if permission is found in extended policies
      }
    }
  }

  return isStaff || canView;
};

export const isConsentValid = (consentDataSource) => {
  const currentDate = new Date(); // Get current date

  // Loop through the consents to check the conditions
  for (const consent of consentDataSource) {
    const { fileUrl, expiresAt } = consent;
    const expirationDate = new Date(expiresAt);

    // Check if the consent has a file and expiration date is in the future
    if (fileUrl && expirationDate > currentDate) {
      return true; // Valid if condition 1 is met
    }

    // Check if the consent does not have a file and expiration date is in the future
    if (!fileUrl && expirationDate > currentDate) {
      return true; // Valid if condition 2 is met
    }
  }
  return false; // Invalid if neither condition is met
}